import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';

const data = [
  {
    link: 'https://github.com/RashmiNirasha',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://web.facebook.com/deepal.gunawardana.9',
    label: 'Facebook',
    icon: faFacebookF,
  },
  {
    link: 'https://www.instagram.com/rush_n_g_/?hl=en',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://www.linkedin.com/in/rashmi-gunawardana-7106761ab/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'mailto:nirashagunawardana9@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
